import { Button, Result } from 'antd';
import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import BasicLayout from '../layouts/basic';
import { RG_ROUTES } from 'src/consts/rgRoutes';
import { isGroupUserSelector } from 'src/selector/app';
import { currentUserSelector } from 'src/selector/user';
import { useSelector } from 'react-redux';
import { PageDashboard } from 'src/consts/routes';
import Head from 'next/head';

const NoFoundPage = () => {
  const router = useRouter();
  const isGroup = useSelector(isGroupUserSelector);
  const { currentUser } = useSelector(currentUserSelector);

  const goHome = useCallback(() => {
    let path = '/';

    if (currentUser) {
      path = isGroup ? RG_ROUTES.report_earnings : PageDashboard;
    }

    router.replace(path);
  }, [currentUser]);

  const content = (
    <React.Fragment>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <Result
        status="404"
        title="Page not exist"
        subTitle={<FormattedMessage id="page404.subtitle" />}
        extra={
          <Button type="primary" onClick={goHome}>
            <FormattedMessage id="action.backHome" />
          </Button>
        }
      />
    </React.Fragment>
  );

  if (!currentUser) return content;

  return <BasicLayout>{content}</BasicLayout>;
};

export default NoFoundPage;
